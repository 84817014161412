<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center w-100 mb-1">
      <h3>إصدار التطبيق</h3>
      <div>
        <b-button variant="primary" @click="onUpdateClicked()">
          <feather-icon icon="editIcon" />
          تعديل</b-button
        >
      </div>
    </div>
    <hr />
    <div>
      <b-row v-if="loading">
        <b-col v-for="n in 4" :key="n">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="85%"></b-skeleton>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <div>
            <h5 class="text-primary">رقم الإصدار</h5>
            <span>{{ versions.number }}</span>
          </div>
        </b-col>
        <b-col>
          <div>
            <h5 class="text-primary">إجبار المستخدم على تحديث النسخة</h5>
            <span>{{ versions.is_mandatory ? "نعم" : "لا" }}</span>
          </div>
        </b-col>
        <b-col>
          <div>
            <h5 class="text-primary">تاريخ اخر نسخة</h5>
            <span>{{ versions.released_at }}</span>
          </div>
        </b-col>
        <b-col>
          <div>
            <h5 class="text-primary">رابط التحميل</h5>
            <span>{{ versions.download_link }}</span>
          </div>
        </b-col>
      </b-row>
    </div>
    <form-modal
      ref="estateModalV"
      :formSchema="estatesFormSchema"
      title="تعديل الإصدار"
      v-model="activModel"
      :loading="loading"
      @confirm="onModalConfirmed"
      size="lg"
    >
    </form-modal>
  </b-card>
</template>

<script>
import { BCard, BButton, BRow, BCol,BSkeleton } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import FormSelect from "@/components/form-select/index.vue";
import FormModal from "@/components/FormModal.vue";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    FormSelect,
    FormModal,
    BSkeleton
  },
  data() {
    return {
      activModel: false,
      estatesFormSchema: {
        number: {
          component: "FormInput",
          key: "number",
          attrs: {
            label: "رقم الإصدار",
            rules: "required",
          },
        },
        is_mandatory: {
          component: "FormSelect",
          key: "is_mandatory",
          attrs: {
            id: "is_reservation",
            label: "إجبار المستخدم على تحديث النسخة",
            rules: "required",
            reduce: (el) => el.value,
            options: [
              { label: "لا", value: 0 },
              { label: "نعم", value: 1 },
            ],
          },
        },
        released_at: {
          component: "FormDatepicker",
          key: "released_at",
          attrs: {
            id:"date_about",
            label: "تاريخ اخر نسخة",
            rules: "required",
            placeholder: "YY-MM-DD",
          },
        },
        download_link: {
          component: "FormInput",
          key: "download_link",
          attrs: {
            label: "رابط التحميل",
            rules: "required",
          },
        },
      },
    };
  },
  methods: {
    ...mapActions("settings/aboutManaraApp", ["getVersions", "updateVersions"]),
    onUpdateClicked() {
      var toUpdate = {
        id: this.versions.id,
        number: this.versions.number,
        download_link: this.versions.download_link,
        is_mandatory: this.versions.is_mandatory,
        released_at: this.versions.released_at,
      };
      this.$refs.estateModalV.init(toUpdate);
      this.activModel = true;
    },
    onModalConfirmed(form) {
      if (form.id) {
        this.updateVersions({ id: form.id, data: form }).then(() => {
          this.getVersions();
          this.activModel = false;
        });
      }
    },
  },
  computed: {
    ...mapGetters("settings/aboutManaraApp", ["about", "versions", "loading"]),
  },
  created() {
    this.getVersions();
  },
};
</script>

